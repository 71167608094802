<template>
  <div>
    <h3 class="mb-10 font-weight-bold text-dark" id="formTest">
      1. Nhập thông tin bài học / bài kiểm tra
    </h3>
    <v-col cols="8">
      <div>
        <b-form-group
          v-if="courseId"
          class="mb-0"
          label-cols="4"
          label-cols-lg="2"
          label-size="lg"
          label="Có trả phí"
        >
          <v-switch
            class="mt-0 pt-0"
            v-model="paid_input"
            :label="capitalizeString(`${paid_input.toString()}`)"
            color="info"
            hide-details
          >
          </v-switch>
        </b-form-group>
        <b-form-group
            class="mb-0"
            label-cols="4"
            label-cols-lg="2"
            label-size="lg"
            label="Kỳ thi"
        >
          <v-select
            :items="product_categories"
            v-model="product_category_id"
            item-value="id"
            item-text="name.vi"
            dense outlined clearable
            placeholder="Chọn kỳ thi tương ứng với bài học/ bài kiểm tra"
          >
          </v-select>
        </b-form-group>
        <b-form-group
          label-cols="4"
          label-cols-lg="2"
          label-size="lg"
          label="Loại nội dung"
        >
          <b-form-radio-group v-model="type_expand">
            <b-form-radio :value="test_type_expand_constant.LESSON" size="lg">Bài học (Lesson)</b-form-radio>
            <b-form-radio :value="test_type_expand_constant.TEST" size="lg"
              >Bài kiểm tra (Test)</b-form-radio
            >
          </b-form-radio-group>
        </b-form-group>

        <b-form-group
            v-if="canInputCourseTopic()"
            label-cols="4"
            label-cols-lg="2"
            label-size="lg"
            label="Chọn topic PTE (course)"
        >
          <v-autocomplete
              :items="list_course_by_category"
              v-model="topic_id"
              item-value="id"
              item-text="name.vi"
              dense outlined clearable chips
              placeholder="Bấm để chọn topic"
          ></v-autocomplete>
        </b-form-group>

        <b-form-group
            v-if="canInputLevel()"
            label-cols="4"
            label-cols-lg="2"
            label-size="lg"
            label="Chọn trình độ"
        >
          <v-autocomplete
              v-model="level_id"
              :items="dataFilterLevelState()"
              item-value="id"
              item-text="name"
              dense outlined clearable
              placeholder="Bấm để chọn trình độ"
          >
          </v-autocomplete>
        </b-form-group>

        <b-form-group
            v-if="canInputSituationCodeForTestInfo()"
            label-cols="4"
            label-cols-lg="2"
            label-size="lg"
            label="Situation Code"
        >
          <v-autocomplete
              v-model="situation_code"
              :items="list_situation_code"
              item-value="value"
              item-text="label"
              dense outlined clearable chips
              placeholder="Bấm để chọn situation code"
          >
            <template v-slot:item="{item}">
              <div class="pt-1 pb-1">
                <span class="font-weight-bold">{{item.value}}</span> ({{item.label}})
              </div>
            </template>
            <template v-slot:selection="{ item, index }">
              <template v-if="item">
                <div class="position-absolute left-0 right-0 black--text selection-figure-span" style="overflow: hidden; text-overflow: ellipsis; white-space: nowrap">
                  <span>
                    <span class="font-weight-bold">{{item.value}}</span> ({{item.label}})
                  </span>
                </div>
              </template>
              <template v-else>
                <span class="grey--text">Bấm để chọn situation code </span>
              </template>
            </template>
          </v-autocomplete>

        </b-form-group>

        <b-form-group
          label-cols="4"
          label-cols-lg="2"
          label-size="lg"
          label="Tiêu đề"
        >
          <b-form-input
            v-model="title_input"
            size="md"
            placeholder="Enter text"
          ></b-form-input>
        </b-form-group>
        <b-form-group
          label-cols="4"
          label-cols-lg="2"
          label-size="lg"
          label="Tiêu đề phụ"
        >
          <Editor v-model="sub_title_input"></Editor>
        </b-form-group>
        <b-form-group
          label-cols="4"
          label-cols-lg="2"
          label-size="lg"
          label="Giới hạn thời gian"
        >
          <b-form-radio-group v-model="time_restriction_input">
            <b-form-radio value="Yes" size="lg">Có</b-form-radio>
            <b-form-radio value="No" size="lg">Không</b-form-radio>
          </b-form-radio-group>
        </b-form-group>
        <template v-if="time_restriction_input === 'Yes'">
          <b-form-group
            label-cols="4"
            label-cols-lg="2"
            label-size="lg"
            label="Thời gian (giờ:phút)"
          >
            <date-picker
              v-model="duration_input"
              type="time"
              format="HH:mm"
              value-type="format"
              placeholder="hh:mm"
            ></date-picker>
          </b-form-group>
        </template>
        <b-form-group
          label-cols="4"
          label-cols-lg="2"
          label-size="lg"
          label="Sử dụng ngày hết han đề (Test Practice)"
        >
          <div class="d-flex">
            <b-form-radio-group v-model="predict_input" class="pt-1">
              <b-form-radio :value="1" size="lg">Có</b-form-radio>
              <b-form-radio :value="0" size="lg">Không</b-form-radio>
            </b-form-radio-group>
            <date-picker
              v-if="predict_input == 1"
              class="ml-1"
              v-model="expired_predict_date_input"
              value-type="format"
              format="YYYY-MM-DD"
            />
          </div>
        </b-form-group>
        <b-form-group
          label-cols="4"
          label-cols-lg="2"
          label-size="lg"
          label="Nội dung mô tả"
        >
          <Editor v-model="description_input"></Editor>
        </b-form-group>
        <b-form-group
          label-cols="4"
          label-cols-lg="2"
          label-size="lg"
          label="Ảnh đại diện"
        >
<!--          <ImageCompress :done="getFiles"></ImageCompress>-->
          <BImageWithValidateSize :done="getFiles" file-label="Ảnh đại diện" ></BImageWithValidateSize>
          <div style="width: 50%" v-if="cover_test_data !== null">
            <b-img
              class="mt-1"
              rounded
              height="200px"
              :src="cover_test_data"
            ></b-img>
          </div>
        </b-form-group>
        <b-form-group
          label-cols="4"
          label-cols-lg="2"
          label-size="lg"
          label="File pdf đề thi"
        >
          <b-form-file
            @change="onPdfTestChange"
            placeholder="Chọn tệp tin hoặc cancel để xóa"
            accept="application/pdf"
          ></b-form-file>
          <div style="width: 100%">
            <b-embed
              v-if="pdf_test_data !== null"
              type="iframe"
              aspect="16by9"
              :src="pdf_test_data"
            ></b-embed>
          </div>
        </b-form-group>
      </div>
    </v-col>
    <!-- Nhập thang điểm -->
    <v-row>
      <v-col cols="4">
        <b-form-group
          label-cols="4"
          label-cols-lg="2"
          label-size="lg"
          label="Thang điểm"
          style="margin-bottom: 0px"
        >
          <v-autocomplete
            v-model="grading_scale_type"
            :items="grading_scale_tags"
            filled
            small-chips
            color="blue-grey lighten-2"
            label=""
          >
          </v-autocomplete>
        </b-form-group>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="4">
        <b-form-group
          label-cols="4"
          label-cols-lg="2"
          label-size="lg"
          label="Thêm Tags"
          style="margin-bottom: 0px"
        >
          <multiselect
            v-model="tag_selected"
            tag-placeholder="Tạo tag này"
            placeholder="Tìm kiếm hoặc tạo tag"
            label="name"
            track-by="id"
            :options="tags"
            :multiple="true"
            :taggable="true"
          ></multiselect>
        </b-form-group>
      </v-col>
    </v-row>
    <!-- kết thúc nhập thang điểm -->
  </div>
</template>

<script>
import ApiService from "@/service/api.service";
import { mapState, mapGetters } from "vuex";
import _ from "lodash";
import constantTestBuilder from "../mixins/Support/constantTestBuilder";
import testInformationSupport from "./Support/testInformationSupport";
export default {
  name: "InformationForm",
  components: {
    Editor: () => import("@/components/ckeditor5/Editor.vue"),
    // ImageCompress: () => import("@/components/image/ImageCompress"),
    BImageWithValidateSize: () => import("@/components/image/B-ImageWithValidateSize"),
    // ProductCategorySelect: () => import("@/components/Selects/ProductCategorySelect"),
  },
  mixins: [
    constantTestBuilder,
      testInformationSupport,
  ],
  props: ["courseId"],
  data() {
    return {
      grading_scale_tags: [],
      //grading_scale_type: null,
    };
  },
  computed: {
    ...mapState({
      title_test: (state) => state.informationTestBuilderStore.title_test,
      sub_title: (state) => state.informationTestBuilderStore.sub_title,
      description_test: (state) =>
        state.informationTestBuilderStore.description_test,
      time_restriction_test: (state) =>
        state.informationTestBuilderStore.time_restriction_test,
      duration_test: (state) => state.informationTestBuilderStore.duration_test,
      tags: (state) => state.informationTestBuilderStore.tags,
      product_categories: (state) => state.contentTestBuilderStore.product_categories,
    }),
    ...mapGetters({
      cover_test_data: "informationTestBuilderStore/cover_test_data",
      cover_test_file: "informationTestBuilderStore/cover_test_file",
      is_upload_cover_test: "informationTestBuilderStore/is_upload_cover_test",
      data_option_skills: "informationTestBuilderStore/data_option_skills",
    }),
    paid_input: {
      get() {
        return this.$store.state.informationTestBuilderStore.paid_test;
      },
      set(value) {
        this.$store.commit("informationTestBuilderStore/setPaidTest", value);
      },
    },
    title_input: {
      get() {
        return this.title_test;
      },
      set(value) {
        this.$store.commit("informationTestBuilderStore/setTitleTest", value);
      },
    },
    sub_title_input: {
      get() {
        return this.sub_title;
      },
      set(value) {
        this.$store.commit("informationTestBuilderStore/setSubTitle", value);
      },
    },
    description_input: {
      get() {
        return this.description_test;
      },
      set(value) {
        this.$store.commit(
          "informationTestBuilderStore/setDescriptionTest",
          value
        );
      },
    },
    type_expand: {
      get() {
        return this.$store.state.informationTestBuilderStore.type_expand;
      },
      set(value) {
        this.$store.commit("informationTestBuilderStore/setTypeExpand", value);
      },
    },
    time_restriction_input: {
      get() {
        return this.time_restriction_test;
      },
      set(value) {
        this.$store.commit(
          "informationTestBuilderStore/setTimeRestrictionTest",
          value
        );
      },
    },
    duration_input: {
      get() {
        return this.duration_test;
      },
      set(value) {
        this.$store.commit(
          "informationTestBuilderStore/setDurationTest",
          value
        );
      },
    },
    cover_data: {
      get() {
        return this.cover_test_data;
      },
    },
    grading_scale_type: {
      get() {
        return this.$store.state.informationTestBuilderStore.grading_scale;
      },
      set(value) {
        this.$store.commit(
          "informationTestBuilderStore/setGradingScale",
          value
        );
      },
    },
    tag_selected: {
      get() {
        return this.$store.state.informationTestBuilderStore.tag_selected;
      },
      set(value) {
        this.$store.commit("informationTestBuilderStore/setTagSelected", value);
      },
    },
    pdf_test_data: {
      get() {
        return this.$store.state.informationTestBuilderStore.pdf_test_data;
      },
    },
    predict_input: {
      get() {
        return this.$store.state.informationTestBuilderStore.predict;
      },
      set(value) {
        this.$store.commit("informationTestBuilderStore/setPredict", value);
      },
    },
    expired_predict_date_input: {
      get() {
        return this.$store.state.informationTestBuilderStore.expired_predict_date;
        },
      set(value) {
        this.$store.commit("informationTestBuilderStore/setExpiredPredictDate", value);
      },
    },
    product_category_id: {
      get() {
        return this.$store.state.informationTestBuilderStore.product_category_id;
        },
      set(value) {
        this.$store.commit("informationTestBuilderStore/setProductCategoryId", value);
      },
    },
    list_course_by_category: {
      get() {
        return this.$store.state.informationTestBuilderStore.list_course_by_category;
      },
      set(value) {
        this.$store.commit("informationTestBuilderStore/setListCourseByCategory", value);
      },
    },
    topic_id: {
      get() {
        return this.$store.state.informationTestBuilderStore.topic_id;
      },
      set(value) {
        this.$store.commit("informationTestBuilderStore/setTopicId", value);
      },
    },
    list_situation_code: {
      get() {
        return this.$store.state.informationTestBuilderStore.list_situation_code;
      },
      set(value) {
        this.$store.commit("informationTestBuilderStore/setListSituationCode", value);
      },
    },
    situation_code: {
      get() {
        return this.$store.state.informationTestBuilderStore.situation_code;
      },
      set(value) {
        this.$store.commit("informationTestBuilderStore/setSituationCode", value);
      },
    },
    levels: {
      get() {
        return this.$store.state.informationTestBuilderStore.levels;
      },
      set(value) {
        this.$store.commit("informationTestBuilderStore/setLevels", value);
      },
    },
    level_id: {
      get() {
        return this.$store.state.informationTestBuilderStore.level_id;
      },
      set(value) {
        this.$store.commit("informationTestBuilderStore/setLevelId", value);
      },
    },
  },
  watch: {
    // product_category_id(val) {
    //   if (val === this.product_category_constant.PREPTALK_ENGLISH_V2) {
    //
    //   }
    // },
  },
  created() {
    this.getGradingScale();
    this.getAllTagSkill();
  },
  methods: {
    async getAllTagSkill() {
      let vm = this;
      await ApiService.get("prep-app/tag/tag-type").then(function (response) {
        let data_option_skills = response.data["skilllib"].map((e) => {
          return e.name;
        });
        vm.$store.commit(
          "informationTestBuilderStore/setOptionSkill",
          data_option_skills
        );
      });
    },
    async getGradingScale() {
      let vm = this;
      await ApiService.get("prep-app/grading-scale").then(function (response) {
        vm.grading_scale_tags = response.data.map((e) => {
          return e.name;
        });
      });
    },


    getFiles(obj) {
      if (obj.blob) {
        this.$store.commit("informationTestBuilderStore/setIsUploadCoverTest", 0);
      } else {
        this.$store.commit("informationTestBuilderStore/setIsUploadCoverTest", 1);
      }
      this.$store.commit("informationTestBuilderStore/setCoverTest", obj.blob);
      this.$store.commit("informationTestBuilderStore/setCoverFileTest", obj.file);
      // console.log(obj);
    },

    capitalizeString(text) {
      return _.capitalize(text);
    },

    onPdfTestChange(e) {
      let file = e.target.files[0];
      let vm = this;
      if (file === undefined) {
        this.$store.commit("informationTestBuilderStore/setPdfTest", null);
        this.$store.commit("informationTestBuilderStore/setPdfFileTest", null);
        this.$store.commit("informationTestBuilderStore/setIsUploadPdfTest", 1);
        return;
      }
      this.$store.commit("informationTestBuilderStore/setPdfFileTest", file);
      let reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = function (e) {
        vm.$store.commit(
          "informationTestBuilderStore/setPdfTest",
          e.target.result
        );
        vm.$store.commit("informationTestBuilderStore/setIsUploadPdfTest", 0);
      };
    },
    dataFilterLevelState() {
      let vm = this;
      let levels = JSON.parse(JSON.stringify(this.levels));
      if(!this.$utils.isValueInvalid(this.product_category_id)) {
        levels = levels.filter((level) => {
          return level.category_id === vm.product_category_id;
        });
      }
      // sort list level by offset
      levels.sort((a, b) => {
        return a.offset - b.offset;
      });
      return levels;
    },

  },

};
</script>

<style></style>
